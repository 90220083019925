import { Platform } from 'react-native';
import { logger, consoleTransport } from 'react-native-logs';

const OS = Platform.select({
    ios: Platform.OS,
    android: 'and',
    web: Platform.OS,
}) as string;

export const LOG = logger.createLogger({
    enabled: __DEV__,
    transport: consoleTransport,
    severity: 'debug',
    levels: {
        info: 0,
        app: 1,
        auth: 2,
        api: 3,
        get: 4,
        post: 5,
        del: 6,
        store: 7,
        screen: 8,
        error: 9,
    },
    transportOptions: {
        colors: {
            info: 'white',
            app: 'white',
            auth: 'magenta',
            store: 'blueBright',
            screen: 'cyan',
            error: 'redBright',
        },
        extensionColors: {
            ios: 'blue',
            and: 'grey',
            web: 'white',
        },
    },
});

export default LOG.extend(OS);
