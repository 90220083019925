import LOG from '@core/utils/Logger';
import { register } from 'register-service-worker';

const version = import.meta.env.VITE_APP_VERSION;
LOG.app(`Service Worker ${version}`);

register(`/service-worker.js?v=${version}`, {
  ready() {
    LOG.app('Service Worker » Ready');
  },
  registered() {
    LOG.app('Service Worker » Registered');
  },
  offline() {
    LOG.app('Service Worker » Offline');
  },
  error(err) {
    LOG.error(`Service Worker » ${err.message}`);
  },
});
