import { LoadSkiaWeb } from '@shopify/react-native-skia/lib/module/web';
import * as SystemUI from 'expo-system-ui';
import { AppRegistry } from 'react-native';
import { name as appName } from '../../../../app.json';
import { backgroundColor } from '../../../../app.json';
import '../style.css';

LoadSkiaWeb({ locateFile: () => '/canvaskit.wasm' }).then(async () => {
  await SystemUI.setBackgroundColorAsync(backgroundColor);
  const App = (await import('@app')).default;
  AppRegistry.registerComponent(appName, () => App);
  AppRegistry.runApplication(appName, {
    rootTag: document.getElementById('root'),
  });
});

import '/@/utils/ServiceWorker';
